import { Alert } from "@backstage-community/plugin-cost-insights";
import { MarkdownContent } from "@backstage/core-components";
import { Alert as BackendAlert } from '@internal/backstage-plugin-cost-insights-backend/src/services/CostInsightsService';
import React from "react";

export class MarkdownAlert implements Alert {
    private readonly alert: BackendAlert;

    constructor(alert: BackendAlert) {
        this.alert = alert;
    }

    get title() {
        return this.alert.title;
    }

    get subtitle() {
        return this.alert.subtitle;
    }

    get url() {
        return this.alert.url;
    }

    get element() {
        return <MarkdownContent content={this.alert.description} linkTarget="_blank" />
    }
}